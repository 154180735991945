<template>
    <div class="row">
        <div class="col-md-12">
            <card class="card-plain" body-classes="table-full-width">
                <template slot="header">
                    <h4 class="card-title">用户管理</h4>
                    <p class="category">添加，删除或修改用户的信息</p>
                </template>
                <el-tooltip
                        content="新建用户"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                >
                    <base-button type="link" style="float:right;color: #cccccc;"
                                 @click.native="openUserAddingForm">
                        <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                </el-tooltip>
                <el-table
                        header-cell-class-name="table-transparent"
                        header-row-class-name="table-transparent"
                        row-class-name="table-transparent"
                        :data="users"
                >
                    <el-table-column min-width="150" label="账号" sortable >
                        <template  slot-scope="{ row }">
                            {{row._links.self.href.split("/").pop()}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            min-width="150"
                            sortable
                            label="姓名"
                            property="name"
                    ></el-table-column>
                    <el-table-column
                            min-width="150"
                            sortable
                            label="角色"
                            property="role"
                    ></el-table-column>
                    <el-table-column
                            min-width="150"
                            header-align="right"
                            align="right"
                            label=""
                    >
                        <template  slot-scope="{ row , $index }">
                            <el-tooltip
                                    v-if="row.role!=='C_ADMIN'"
                                    content="移除"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top"
                            >
                                <base-button
                                        type="danger"
                                        icon
                                        size="sm"
                                        class="btn-link"
                                        @click.native="deleteUser(row,$index)"
                                >
                                    <i class="tim-icons icon-simple-remove"></i>
                                </base-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </card>
        </div>

        <!-- Classic Modal -->
        <modal
                :class="{ largeModal:modal.largeModal, dark:modal.dark }"
                :show.sync="modal.open"
                v-if="modal.open"
                headerClasses="justify-content-center"
        >
            <h4 slot="header" class="title title-up">{{modal.title}}</h4>
            <component @addingUser="addingUser" :is="modal.componentName"></component>
        </modal>
    </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';

import userService from 'src/services/user.service';
import { Modal } from 'src/components';

//fomrs
import UserAddingForm from './Forms/UserAddingForm';

export default {
  components: {
    Modal,
    UserAddingForm,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      modal: {
        title:"表格",
        dark:true,
        componentName:"",
        largeModal:false,
        open: false
      },
      users: []
    };
  },
  methods: {
    openUserAddingForm(){
      this.modal.title = "新建用户";
      this.modal.open = true;
      this.modal.componentName = "UserAddingForm";
    },
    closeFormModal(){
      this.modal.title = "";
      this.modal.open = false;
      this.modal.componentName = "";
    },
    addingUser(newUser){
        this.$pop.showLoading();
        userService.addCompanyUser(newUser,this.$router)
            .then(response => {
              this.users.push(response);
              this.closeFormModal();
              this.$pop.close();
            });
    },
    deleteUser(user, index){
        this.$pop.popConfirm("确定要删除?")
        .then((result) => {
          if (result.isConfirmed) {
              this.$pop.showLoading();
              userService.deleteCompanyUser(user,this.$router)
                .then(response => {
                  this.users.splice(index,1);
                  this.$pop.close();
                });
          }
        });
    },
  },
  mounted() {
    userService.retrieveAllUsersOfCompany(this.$router)
        .then(response => {
          this.users = response;
        });
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
