

export default (router) => {
    router.beforeEach((to, from, next) => {
      const publicPages = ['/login'];
      const authRequired = !publicPages.includes(to.path);
      const loggedIn = JSON.parse(sessionStorage.getItem('user'));
      // trying to access a restricted page + not logged in
      // redirect to login page
      if (authRequired && !loggedIn) {
        next('/login');
      } else {
        if(!to.meta.allowedUsers){
            next();
        }else if(to.meta.allowedUsers.includes(loggedIn.role)){
            next();
        }
      }
    });
};