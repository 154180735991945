<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
                name="name"
                :rules="{
					required: true,
					projectName: '^[a-z]{3,15}$'
				}"
                v-slot="{ passed, failed, errors }"
        >
          <base-input
                  v-model="project.name"
                  label="项目名"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <base-input label="项目描述">
            <textarea
                    class="form-control"
                    placeholder="项目描述"
                    v-model="project.description"
            >
            </textarea>
        </base-input>
        <div class="text-right">
          <base-button
                  v-if="isChanged"
                  native-type="submit"
                  type="primary"
          >编辑</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("required", {
  ...required,
  message: '不能为空！'
});
extend("projectName", {
  ...regex,
  message: '由3-15个小写字母组成！'
});

export default {
  components: {
    BaseCheckbox
  },
  props: {
    selectedProject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      project:JSON.parse(JSON.stringify(this.selectedProject)),
      isChanged:false
    };
  },
  methods: {
    submit() {
      this.$emit('editingProject', this.project);
    }
  },
  watch: {
    'project': {
      handler(newValue, oldValue) {
        this.isChanged = true;
      },
      deep: true
    }
  },
  mounted() {
  }
};
</script>
<style></style>
