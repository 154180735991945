var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"账 号","error":errors[0]},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"密 码","error":errors[0]},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"姓 名","error":errors[0]},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v("创建")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }