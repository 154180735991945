import axios from './http';
import authHeader from './auth-header';
import errorHandler from './error-handler';

class UserService {
  retrieveAllUsersOfCompany(router) {
    return axios.get('/users/search/retrieveAllUsersOfCompany', { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data._embedded.users);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  addCompanyUser(newUser, router) {
    return axios.post('/users', newUser, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  deleteCompanyUser(user, router) {
    let username = user._links.self.href.split("/").pop();
    return axios.delete("/users/"+username, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve();
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

}

export default new UserService();
