<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">


      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <div class="photo"><i class="tim-icons icon-settings-gear-63"></i></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none"></p>
        </template>
        <li class="nav-link">
          <a href="#" @click="openPasswordChangingForm" class="nav-item dropdown-item">修改密码</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click="logout">登 出</a>
        </li>
      </base-dropdown>
    </ul>
    <!-- Classic Modal -->
    <modal
            :class="{ largeModal:modal.largeModal, dark:modal.dark }"
            :show.sync="modal.open"
            v-if="modal.open"
            headerClasses="justify-content-center"
    >
      <h4 slot="header" class="title title-up">{{modal.title}}</h4>
      <component :is="modal.componentName"
                 @passwordChanged="passwordChanged"
      ></component>
    </modal>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import PasswordChangingForm from './Forms/PasswordChangingForm.vue';

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    Modal,
    PasswordChangingForm
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      modal: {
        title:"表格",
        dark:true,
        componentName:"PasswordChangingForm",
        largeModal:false,
        open: false
      },
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
		this.$store.dispatch('auth/logout').then(
			this.$router.push('login')
		 );
    },
    closeFormModal(){
      this.modal.title = "";
      this.modal.open = false;
    },
    openPasswordChangingForm(){
      this.modal.title = "修改密码";
      this.modal.open = true;
      this.modal.largeModal = false;
    },
    passwordChanged(){
      this.closeFormModal();
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
