import pop from "src/pop-up";

export default function (error,router) {
		if (error.response) {
		    let errMsg = JSON.stringify(error.response.data)
			switch (error.response.status) {
                case 400:
                        if(error.response.data.errors){
                            pop.popError(error.response.status,error.response.data.errors[0].message);
                        }else{
                            pop.popError(error.response.status,errMsg);
                        }
					break;
				case 401:
				        router.push('login');
					break;
                case 403:
				        pop.popError('没有权限进行此操作！');
					break;
				default:
					pop.popError(error.response.status,errMsg);
			}
		} else if (error.request) {
		    pop.popError(error.message);
		} else {
		    pop.popError(error.message);
		}
	  }