<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">

        <el-table
                header-cell-class-name="table-transparent"
                header-row-class-name="table-transparent"
                row-class-name="table-transparent"
                :data="users"
        >
          <el-table-column min-width="150" label="账号" sortable >
            <template  slot-scope="{ row }">
              {{row._links.self.href.split("/").pop()}}
            </template>
          </el-table-column>
          <el-table-column
                  min-width="150"
                  sortable
                  label="姓名"
                  property="name"
          ></el-table-column>
          <el-table-column
                  min-width="150"
                  sortable
                  label="角色"
          >
            <template  slot-scope="{ row }">
              {{workoutRoleName(row.role)}}
            </template>
          </el-table-column>
          <el-table-column
                  min-width="150"
                  header-align="right"
                  align="right"
                  label=""
          >
            <template  slot-scope="{ row , $index }">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox"
                         @change="onUserSelected($event,row,$index)"
                  />
                  <span class="form-check-sign"></span><!---->
                </label>
              </div>
            </template>
          </el-table-column>
        </el-table>


        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >添 加</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { BaseTable } from '@/components';
import { Table, TableColumn } from 'element-ui';
import {
  BaseSwitch,
  TimeLine,
  TimeLineItem,
} from 'src/components';

import userService from 'src/services/user.service';

export default {
  components: {
    BaseCheckbox,
    BaseTable,
    BaseSwitch,
    TimeLine,
    TimeLineItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      users:[],
      selectedUsers:[]
    };
  },
  methods: {
    workoutRoleName(role){
      switch(role) {
        case 'C_ADMIN':
          return '管理员';
        case 'C_DEV':
          return '开发';
        default:
          return '';
      }
    },
    submit() {
      this.$emit('addingProjectUsers',this.selectedUsers);
    },
    onUserSelected(e,selectedUser,index){
      if(e.target.checked) {
        this.selectedUsers.push(selectedUser);
      }else{
        this.selectedUsers = this.selectedUsers
          .filter(item =>  item._links.self.href!==selectedUser._links.self.href);
      }
    }
  },
  mounted() {
      userService.retrieveAllUsersOfCompany(this.$router)
        .then(response => {
          this.users = response;
        });
  }
};
</script>
<style></style>
