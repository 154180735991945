import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

//projects page
import Projects from 'src/pages/Projects/Projects.vue';

//users page
import Users from 'src/pages/Users/Users.vue';

const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: '登录',
      component: Login
    }
  ]
};

const ProjectEditor = () =>
  import('src/pages/ProjectEditor/projectEditor.vue');
let projectEditorPage = {
  path: '/projectEditor',
  component: ProjectEditor,
  meta: { allowedUsers: ['C_ADMIN','C_DEV'] },
  name: '项目编辑器',
  props: route => ({
	selectedProject: route.params.selectedProject
  })
};

const routes = [
  {
    path: '/',
    redirect: '/projects',
    name: 'Home'
  },
  authPages,
  projectEditorPage,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/projects',
    name: 'Dashboard layout',
    children: [
      {
        path: 'projects',
        name: '项目管理',
        components: { default: Projects }
      },
      {
        path: 'users',
        name: '用戶管理',
        components: { default: Users },
        meta: { allowedUsers: ['C_ADMIN'] },
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
