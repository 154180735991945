<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
                name="o_password"
                rules="required"
                v-slot="{ passed, failed, errors }"
        >
          <base-input
                  required
                  v-model="o_password"
                  type="password"
                  label="原密码"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
                name="password"
                rules="required|confirmed:confirmation"
                v-slot="{ passed, failed, errors }"
        >
          <base-input
                  required
                  v-model="password"
                  type="password"
                  label="新密码"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
                name="confirm"
                vid="confirmation"
                rules="required"
                v-slot="{ passed, failed, errors }"
        >
          <base-input
                  required
                  v-model="confirmation"
                  type="password"
                  label="重复新密码"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >修 改</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import authService from 'src/services/auth.service';

extend("required", {
  ...required,
  message: '不能为空！'
});
extend("confirmed", {
  ...confirmed,
  message: '密码不一致！'
});

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      o_password: "",
      password: "",
      confirmation: "",
    };
  },
  methods: {
    submit() {
      let passwords = {};
      passwords.oldPassword = this.o_password;
      passwords.newPassword = this.password;

      this.$pop.showLoading();
      authService.changePassword(passwords, this.$router)
            .then(response => {
              this.$pop.popSucces("修改成功！");
              this.$emit('passwordChanged');
            });
    }
  }
};
</script>
<style></style>
