<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-sm-6 text-center">
        <card
          type="tasks"
          class="text-left"
          :header-classes="{ 'text-right': $rtl.isRTL }"
        >
          <template slot="header">
            <h6 class="title d-inline">项目 ({{projectsNo}})</h6>
            <p class="card-category d-inline"></p>
            <div v-if="isAdminUser" class="dropdown dropdown">
              <el-tooltip
                      content="新建项目"
                      effect="light"
                      :open-delay="300"
                      placement="top"
              >
                <base-button aria-label="dropdown"
                             data-toggle="dropdown"
                             class="dropdown-toggle no-caret btn btn-link btn-icon"
                             @click.native="openProjectAddingForm">
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </div>
          </template>
          <div class="table-full-width table-responsive" style="height:410px">
            <base-table :data="projects" thead-classes="text-primary">
              <template slot-scope="{ row, index }">
                <td>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="checkbox"
                              v-model="row.selected"
                              @change="onProjectSelected($event,row,index)"
                      />
                      <span class="form-check-sign"></span><!---->
                    </label>
                  </div>
                </td>
                <td>
                  <a style="cursor: pointer;" @click="goToProjectEditor(row)">
                    <p class="title">{{ row.name }}</p>
                  </a>
                  <p class="text-muted">{{ row.description }}</p>
                </td>
                <td class="td-actions text-right">
                  <base-dropdown
                            menu-on-right=""
                            tag="div"
                            title-classes="btn btn-link btn-icon"
                            :class="{ 'float-left': $rtl.isRTL }"
                    >
                      <i slot="title" class="tim-icons icon-settings-gear-63"></i>
                      <a v-if="isAdminUser" class="dropdown-item" @click="openProjectEditingForm(row,index)" >编辑项目</a>
                      <a class="dropdown-item" @click="downloadProject(row)">下载项目</a>
                      <a v-if="isAdminUser" class="dropdown-item" @click="deleteProject(row,index)" style="color: red;">删除项目</a>
                  </base-dropdown>
                </td>
              </template>
            </base-table>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-sm-6">
        <card type="timeline" class="card-plain">
          <time-line type="simple">
            <time-line-item
              inverted
              badge-type="success"
              badge-icon="tim-icons icon-single-02"
            >
              <span slot="header" class="badge badge-success">{{checkedProject?checkedProject.name:''}} 项目开发人员</span>
              <div slot="content" v-if="checkedProject">
                <el-tooltip v-if="isAdminUser"
                        content="添加人员"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                >
                  <base-button type="link" style="float:right;color: #cccccc;" @click.native="openProjectUsersAddingForm">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </el-tooltip>
                <el-table
                        header-cell-class-name="table-transparent"
                        header-row-class-name="table-transparent"
                        row-class-name="table-transparent"
                        :data="projectAllowedUsers"
                >
                  <el-table-column min-width="150" label="账号" sortable >
                    <template  slot-scope="{ row }">
                      {{row._links.self.href.split("/").pop()}}
                    </template>
                  </el-table-column>
                  <el-table-column
                          min-width="150"
                          sortable
                          label="姓名"
                          property="name"
                  ></el-table-column>
                  <el-table-column
                          min-width="150"
                          sortable
                          label="角色"
                  >
                    <template  slot-scope="{ row }">
                      {{workoutRoleName(row.role)}}
                    </template>
                  </el-table-column>
                  <el-table-column
                          v-if="isAdminUser"
                          min-width="150"
                          header-align="right"
                          align="right"
                          label=""
                  >
                    <template  slot-scope="{ row , $index }">
                      <el-tooltip
                              content="移除"
                              effect="light"
                              :open-delay="300"
                              placement="top"
                      >
                        <base-button
                                type="danger"
                                icon
                                size="sm"
                                class="btn-link"
                                @click.native="removeUserFromProject(row,$index)"
                        >
                          <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </time-line-item>
          </time-line>
        </card>
      </div>
    </div>

    <!-- Classic Modal -->
    <modal
      :class="{ largeModal:modal.largeModal, dark:modal.dark }"
      :show.sync="modal.open"
      v-if="modal.open"
      headerClasses="justify-content-center"
    >
      <h4 slot="header" class="title title-up">{{modal.title}}</h4>
      <component @addingProject="addingProject"
                 @editingProject="editingProject"
                 @addingProjectUsers = addingProjectUsers
                 :is="modal.componentName"
                 :selectedProject="selectedProject"
      ></component>
    </modal>
  </div>
</template>
<script>
import { BaseTable } from '@/components';
import { Table, TableColumn } from 'element-ui';
import {
  BaseSwitch,
  TimeLine,
  TimeLineItem,
} from 'src/components';
import { Modal } from 'src/components';
import projectService from 'src/services/project.service';

//fomrs
import ProjectAddingForm from './Forms/ProjectAddingForm.vue';
import ProjectEditingForm from './Forms/ProjectEditingForm.vue';
import ProjectUsersAddingForm from './Forms/ProjectUsersAddingForm.vue';

export default {
  components: {
    ProjectAddingForm,
    ProjectEditingForm,
    ProjectUsersAddingForm,
    Modal,
    BaseTable,
    BaseSwitch,
    TimeLine,
    TimeLineItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      modal: {
        title:"表格",
        dark:true,
        componentName:"ProjectAddingForm",
        largeModal:false,
        open: false
      },
      selectedProject:null,
      checkedProject:null,
      projectAllowedUsers:[],
      projects: []
    };
  },
  methods: {
    workoutRoleName(role){
      switch(role) {
        case 'C_ADMIN':
          return '管理员';
        case 'C_DEV':
          return '开发';
        default:
          return '';
      }
    },
    openProjectAddingForm(){
      this.modal.title = "新建项目";
      this.modal.open = true;
      this.modal.largeModal = false;
      this.modal.componentName = "ProjectAddingForm";
    },
    openProjectEditingForm(project, index){
      this.selectedProject = project;
      this.modal.largeModal = false;
      this.selectedProject.index = index;
      this.modal.title = "编辑项目";
      this.modal.open = true;
      this.modal.componentName = "ProjectEditingForm";
    },
    openProjectUsersAddingForm(){
      this.modal.title = "添加项目开发人员";
      this.modal.largeModal = true;
      this.modal.open = true;
      this.modal.componentName = "ProjectUsersAddingForm";
    },
    closeFormModal(){
      this.modal.title = "";
      this.modal.open = false;
      this.modal.componentName = "";
    },
    addingProject(project){
        this.$pop.showLoading();
        projectService.addCompanyProject(project,this.$router)
            .then(response => {
              this.projects.push(response);
              this.closeFormModal();
              this.$pop.close();
            });
    },
    editingProject(project){
        this.$pop.showLoading();
        projectService.editCompanyProject(project,this.$router)
            .then(response => {
              this.projects.splice(project.index, 1, response);
              this.closeFormModal();
              this.$pop.close();
            });
    },
    addingProjectUsers(users){
        //不重复的留下
        users = users.filter(u =>  {
          return !this.projectAllowedUsers
                  .find(f => f._links.self.href === u._links.self.href)
        });
        this.$pop.showLoading();
        projectService.addUsersToProject(this.checkedProject,users,this.$router)
          .then(response => {
                this.projectAllowedUsers = this.projectAllowedUsers.concat(users);
                this.closeFormModal();
                this.$pop.close();
              });
    },
    deleteProject(project, index){
        this.$pop.popConfirm("确定要删除?")
        .then((result) => {
          if (result.isConfirmed) {
              this.$pop.showLoading();
              projectService.deleteCompanyProject(project,this.$router)
                .then(response => {
                  this.projects.splice(index,1);
                  this.$pop.close();
                });
          }
        });
    },
    removeUserFromProject(user, index){
        this.$pop.popConfirm("确定从该项目移除用户吗?")
        .then((result) => {
          if (result.isConfirmed) {
              this.$pop.showLoading();
              projectService.removeUserFromProject(this.checkedProject,user,this.$router)
                .then(response => {
                  this.projectAllowedUsers.splice(index,1);
                  this.$pop.close();
                });
          }
        });
    },
    onProjectSelected(e,selectedProject,index){
      if(e.target.checked) {
        this.checkedProject = null;
        this.projectAllowedUsers = [];
        //取回选择project的allowedUser list
        projectService.retrieveProjectAllowedUsers(selectedProject,this.$router)
          .then(response => {
            this.checkedProject = selectedProject;
            this.projectAllowedUsers = response;
          });
        //取消勾选除了选择的project以外的所有project
        this.projects.forEach(
          (p,i) => {
            if(i!==index){
              delete p.selected
            }
          }
        );
      }else{
        this.checkedProject = null;
        this.projectAllowedUsers = [];
      }
    },
    goToProjectEditor(selectedProject) {
        //取回选择project的allowedUser list
        projectService.retrieveProjectAllowedUsers(selectedProject,this.$router)
          .then(response => {
            this.checkedProject = selectedProject;
            let allowedUsers = response.map(x => {
              return x._links.self.href.split("/").pop();
            });
            let loginUserName = sessionStorage.getItem('username');
            //如果当前用户再allowedUser名单中进入编辑器 如果否则告知无权限进入
            if(allowedUsers.some((au,index)=> au===loginUserName)){
              let projectLink = selectedProject._links.self.href;
              this.$router.push({ name: '项目编辑器', params: { selectedProject: projectLink } });
            }else{
              this.$pop.popError('无权访问该项目！');
            }
          });
    },
	downloadProject(project) {
	  //download zip file
	  this.$pop.showLoading();
	  projectService.downloadProject(project,this.$router)
		.then(response => {
		  this.$pop.popSucces('下载成功！');
		  this.$pop.close();
        });
    },
  },
  computed: {
    projectsNo() {
      return this.projects.length;
    },
    isAdminUser () {
      if(this.$store.state.auth.user){
        return this.$store.state.auth.user.role==='C_ADMIN'?true:false;
      }else{
        return false;
      }
    }
  },
  mounted() {
    projectService.retrieveAllProjectsOfCompany(this.$router)
        .then(response => {
          let maxProjectNo = this.$store.state.auth.user.maxProject;
          if(response.length>maxProjectNo){
            this.projects = response.splice(0,maxProjectNo);
          }else {
            this.projects = response;
          }
        });
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
