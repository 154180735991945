import axios from './http';
import authHeader from './auth-header';
import errorHandler from './error-handler';

class ProjectService {
  retrieveAllProjectsOfCompany(router) {
    return axios.get('/projects/search/retrieveAllProjectsOfCompany', { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data._embedded.projects);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  retrieveProjectOfCompany(projectUrl,router) {
    let projectId = projectUrl.split("/").pop();
    return axios.get('/projects/search/retrieveProject', { params: { id: projectId } , headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  retrieveDelegatedProjects(router) {
    return axios.get('/projects/search/retrieveDelegatedProjects', { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data._embedded.projects);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  retrieveProjectAllowedUsers(project,router) {
    let projectId = project._links.self.href.split("/").pop();
    return axios.get('/projects/'+projectId+'/allowedUsers', { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data._embedded.users);
        }).catch(function (error) {
            if(error.response.status===404){
                return Promise.resolve([]);
            }else{
                errorHandler(error,router);
            }
            return Promise.reject(error);
        });
  }

  addCompanyProject(newProject, router) {
    newProject.allowedUsers = [];
    newProject.domainObjects = [];
    return axios.post('/projects', newProject, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  editCompanyProject(project, router) {
    let projectId = project._links.self.href.split("/").pop();
    return axios.patch('/projects/'+projectId, project, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve(response.data);
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  deleteCompanyProject(project, router) {
    let projectId = project._links.self.href.split("/").pop();
    return axios.delete("/projects/"+projectId, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve();
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  addUsersToProject(project, users, router) {
       let projectId = project._links.self.href.split("/").pop();
       let usersString = users.map(m => m._links.self.href).join("\r\n");
       let headers = authHeader();
       headers["Content-Type"] = "text/uri-list";
       return axios.post('/projects/'+projectId+'/allowedUsers',
                        usersString,
                        { headers: headers })
           .then(function (response) {
               return Promise.resolve();
           }).catch(function (error) {
               errorHandler(error,router);
               return Promise.reject(error);
           });
  }

  removeUserFromProject(project, user, router) {
       let projectId = project._links.self.href.split("/").pop();
       let username = user._links.self.href.split("/").pop();
       return axios.delete('/projects/'+projectId+'/allowedUsers/'+username, { headers: authHeader() })
           .then(function (response) {
               return Promise.resolve();
           }).catch(function (error) {
               errorHandler(error,router);
               return Promise.reject(error);
           });
  }

  downloadProject(project,router){
      let projectId = project._links.self.href.split("/").pop();
      project.id = projectId;
      let projectName = project.name;
      let url = "/download/getProjectZip";
      let auHeaders = authHeader();
      return axios
                .post(
                    url,
                    project,
                    {
                        headers: {
                                    "Content-Type": "application/json",
                                    "Accept": "application/zip",
                                    ...auHeaders
                                 },
                        responseType: 'blob',
                    }
                ).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', projectName+'.zip');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    return Promise.resolve();
               }).catch(function (error) {
                    errorHandler(error,router);
                    return Promise.reject(error);
               });
  }

}

export default new ProjectService();
