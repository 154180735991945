import swal from 'sweetalert2';

let pop = {
    popSucces(msg) {
		swal.fire({
		  title: msg,
		  icon: 'success',
		  customClass: {
			confirmButton: 'btn btn-success btn-fill',
			cancelButton: 'btn btn-danger btn-fill'
		  },
		  buttonsStyling: false
		})
	},
    popError(title,msg) {
		swal.fire({
			title: title,
			text: msg,
			buttonsStyling: false,
			confirmButtonText: '确定',
			customClass: {
				confirmButton: 'btn btn-success btn-fill'
			}
		})
	},
    showLoading() {
		swal.fire({
		  title: '处理中...',
		  allowOutsideClick: false, 
		  didOpen: () => {
			swal.showLoading();
		  }
		});
	},
    close() {
		swal.close();
	},
    popConfirm(msg) {
		return swal.fire({
		  title: msg,
		  showCancelButton: true,
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  customClass: {
				confirmButton: 'btn btn-danger btn-fill',
				cancelButton: 'btn btn-success btn-fill'
		  },
		  buttonsStyling: false
		});
	}
};


export default pop;
