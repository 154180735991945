<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
          name="email"
          rules="required"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="user.username"
            label="账 号"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
          name="password"
          rules="required"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="user.password"
            label="密 码"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <ValidationProvider
          name="confirm"
          rules="required"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            required
            v-model="user.name"
            label="姓 名"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >创建</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("required", {required, message: '不能为空！'});

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      user:{
        username: "",
        password: "",
        name: "",
        role:"C_DEV"
      }
    };
  },
  methods: {
    submit() {
      this.$emit('addingUser', this.user);
    }
  }
};
</script>
<style></style>
