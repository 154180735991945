import axios from './http';
import authHeader from './auth-header';
import errorHandler from './error-handler';

class AuthService {
  login(user) {
    return axios
      .post('login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem('username', user.username);
          sessionStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  changePassword(passwords, router) {
    return axios.post('/changePassword', passwords, { headers: authHeader() })
        .then(function (response) {
            return Promise.resolve();
        }).catch(function (error) {
            errorHandler(error,router);
            return Promise.reject(error);
        });
  }

  logout() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('user');
  }

}

export default new AuthService();
