<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <ValidationProvider
          name="name"
          :rules="{
					required: true,
					projectName: '^[a-z]{3,15}$'
				}"
          v-slot="{ passed, failed, errors }"
        >
          <base-input
            v-model="project.name"
            label="项目名"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
        </ValidationProvider>

        <base-input label="项目描述">
            <textarea
                    class="form-control"
                    placeholder="项目描述"
                    v-model="project.description"
            >
            </textarea>
        </base-input>

        <div class="text-right">
          <base-button
                  native-type="submit"
                  type="primary"
          >创建</base-button
          >
        </div>
      </div>
    </div>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("required", {
  ...required,
  message: '不能为空！'
});
extend("projectName", {
  ...regex,
  message: '由3-15个小写字母组成！'
});

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
      project:{
        name: "",
        description: "",
      }
    };
  },
  methods: {
    submit() {
      this.$emit('addingProject',this.project);
    }
  }
};
</script>
<style></style>
