var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"name","rules":{
					required: true,
					projectName: '^[a-z]{3,15}$'
				}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var passed = ref.passed;
				var failed = ref.failed;
				var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"label":"项目名","error":errors[0]},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})]}}],null,true)}),_c('base-input',{attrs:{"label":"项目描述"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.project.description),expression:"project.description"}],staticClass:"form-control",attrs:{"placeholder":"项目描述"},domProps:{"value":(_vm.project.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.project, "description", $event.target.value)}}})]),_c('div',{staticClass:"text-right"},[(_vm.isChanged)?_c('base-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v("编辑")]):_vm._e()],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }