var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('el-table',{attrs:{"header-cell-class-name":"table-transparent","header-row-class-name":"table-transparent","row-class-name":"table-transparent","data":_vm.users}},[_c('el-table-column',{attrs:{"min-width":"150","label":"账号","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row._links.self.href.split("/").pop())+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"150","sortable":"","label":"姓名","property":"name"}}),_c('el-table-column',{attrs:{"min-width":"150","sortable":"","label":"角色"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.workoutRoleName(row.role))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"150","header-align":"right","align":"right","label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('div',{staticClass:"form-check"},[_c('label',{staticClass:"form-check-label"},[_c('input',{attrs:{"type":"checkbox"},on:{"change":function($event){return _vm.onUserSelected($event,row,$index)}}}),_c('span',{staticClass:"form-check-sign"})])])]}}],null,true)})],1),_c('div',{staticClass:"text-right"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v("添 加")])],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }